import React from 'react';
import $ from 'jquery';
import {GoMoveToTop} from "react-icons/go"
import { BsWhatsapp } from 'react-icons/bs';
import {FaFacebookF} from 'react-icons/fa'
import {BiLogoLinkedin} from 'react-icons/bi'

class ScrollToTopButton extends React.Component {
  scrollToTop = () => {
    $('html, body').animate({ scrollTop: 0 }, 'slow');
  };

  render() {
    return (
        <>
      <div className="scroll-to-top" onClick={this.scrollToTop}>
        <div>
            <GoMoveToTop style={{color:'white'}}/>
        </div>
      </div>

      <div className="scroll-to-top-1"  >
      <div>
        <BsWhatsapp/>
        </div>
      </div>

      <div className="scroll-to-top-3"  >
      <div>
        <FaFacebookF/>
        </div>
      </div>
      <div className="scroll-to-top-2"  >
      <div>
        <BiLogoLinkedin/>
        </div>
      </div>

     

      </>
    );
  }
}

export default ScrollToTopButton;
