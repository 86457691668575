import React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'
import { FaInstagram, FaLinkedinIn, FaTwitterSquare } from 'react-icons/fa'
import { BiLogoFacebook, BiLogoTwitter } from 'react-icons/bi'

import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
      <Container fluid>
        <Row className="footer">
          <Col lg={10}>

            <Row>
              <Col lg={4} className='footer-details mt-3 mb-3'>
                <p><Link to={'/about'}>About Us</Link></p>
                <p> <Link to={'/services'}>Services</Link></p>


                <p> <Link to={'/gallery'}>Media</Link></p>
                <p> <Link to={'/contact-us'}>Contact Us</Link></p>

              </Col>
              <Col lg={4} className='footer-details mt-3 mb-3'>

                <p>Email :
                  sales@bahwanautowarehousing.ae
                  binu@dtglobal.com</p>
                <p>Phone :
                  +971 50 462 2615</p>

                <p>Coordinates : 24.85099, 55.0833</p>

              </Col>

              <Col lg={4} className='footer-details mt-3 mb-3'>

              {/* <Row>
                <Col lg={2}><BiLogoFacebook className='footer-icons' /></Col>
                <Col  lg={2}><FaLinkedinIn className='footer-icons' /></Col>
                <Col  lg={2}><BiLogoTwitter className='footer-icons' /></Col>
                <Col  lg={2}><FaInstagram className='footer-icons' /></Col>
              </Row> */}
               <Nav>
              
              <Nav.Link><BiLogoFacebook className='footer-icons' /></Nav.Link>
              <Nav.Link><FaLinkedinIn className='footer-icons' /></Nav.Link>
              <Nav.Link><BiLogoTwitter className='footer-icons' /></Nav.Link>
              <Nav.Link><FaInstagram className='footer-icons' /></Nav.Link>
              </Nav>
              </Col>

            </Row>
            <hr style={{ color: 'white' }}></hr>
           
            <p className='text-center mt-1' style={{ color: "white" }}>
              © 2023 Bahwan Auto Warehousing
            </p>
          </Col>




        </Row>


      </Container>
    </>
  )
}

export default Footer
