import React from 'react';
import { Container, Row, Col, Card, Carousel } from 'react-bootstrap';
import Footer from './Footer';
import Contactbanner from './Contactbanner'
import { BsArrowLeftRight } from 'react-icons/bs';
import { BiSolidStore } from 'react-icons/bi';
import { FcInTransit } from 'react-icons/fc';
import { GrDocumentText } from 'react-icons/gr';
import {GoContainer} from 'react-icons/go';
import {GiCargoShip,GiCargoCrate} from 'react-icons/gi';
import {AiFillCaretRight} from 'react-icons/ai'
import { Helmet } from 'react-helmet';

function About() {
  return (
    <>
    <Helmet>
        <title>Bahwan Warehousing - Services</title>
        <meta name="description" content="Services" />
        <meta name="keywords" content=" Services"></meta>
    </Helmet>
   
      <Container fluid >
        <Row >
      <Carousel style={{padding:'0px'}} >
        <Carousel.Item>
          <img  src='/Asest/service.png' />
        </Carousel.Item>
      </Carousel>
      </Row>
{/*
    <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={10} className='mt-5 mb-5'>
            <Row className='service-row'>
              <Col lg={3} >
                <Card>
                  <div className='text-center mb-3'>
                    <img src='/communications_8948655.png' width={'60px'} />
                  </div>
                  <div>
                    <p className='text-center'>Import / Export documentation and clearance of shipments

                    </p>
                  </div>

                </Card>
              </Col>


              <Col lg={3} >
                <Card>
                  <div className='text-center mb-4'>
                    <img src='\warehouse_2897683.png'  width={'50px'}/>
                  </div>
                  <div>
                    <p className='text-center'>Open storage facility inside JAFZA & Dubai Industrial City

                    </p>
                  </div>

                </Card>
              </Col>
              <Col lg={3} >
                <Card>
                  <div className='text-center mb-4'>
                    <img src='/storage_1261055.png' width={'50px'} />
                  </div>
                  <div>
                    <p className='text-center'>Warehousing facility inside JAFZA & Dubai Industrial City

                    </p>
                  </div>

                </Card>
              </Col>
              <Col lg={3} >
                <Card>
                  <div className='text-center mb-4'>
                    <img src='/MicrosoftTeams-image (90).png' width={'55px'} />
                  </div>
                  <div>
                    <p className='text-center'>Transportations services for customer cargos



                    </p>
                  </div>

                </Card>
              </Col>


              <Col lg={3} >
                <Card>
                  <div className='text-center mb-4'>
                    <img src='\file-extensions_8761827.png'  width={'50px'}/>
                  </div>
                  <div>
                    <p className='text-center'>RTA documentation



                    </p>
                  </div>

                </Card>
              </Col>

              <Col lg={3} >
                <Card>
                  <div className='text-center mb-3'>

                    <img src='/container_6491388.png' width={'55px'} />
                  </div>
                  <div>
                    <p className='text-center'>Container Stuffing



                    </p>
                  </div>

                </Card>
              </Col>

              <Col lg={3} >
                <Card>
                  <div className='text-center mb-4'>

                    <img src='/cargo-ship_10123904.png' width={'50px'} />
                  </div>
                  <div>
                    <p className='text-center'>Sea freight



                    </p>
                  </div>

                </Card>         
                </Col>
            </Row>
          </Col>
        </Row>*/}





        <Row style={{display:'flex',justifyContent:'center'}}>
  <Col lg={10}>
  <h3 className='text-center mb-5 mt-5' >OUR SERVICES</h3>

    <Row className='mt-5'style={{display:'flex',justifyContent:'center'}} >
      <Col lg={3}>
      <div className='text-center mb-3 ' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#90EE90',padding:'30px',borderRadius:'50%'}}> <img src='/communications_8948655.png' width={'60px'} /></div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Import / Export documentation and clearance of shipments

</p>
</div>
      </Col>



      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#ADD8E6',padding:'30px',borderRadius:'50%'}}>
                  <img src='\warehouse_2897683.png'  width={'60px'}/>
                     </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Open storage facility inside JAFZA & Dubai Industrial City

</p>
</div>
      </Col>
   
      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#FFB6C1',padding:'30px',borderRadius:'50%'}}> 
                  <img src='/MicrosoftTeams-image (90).png' width={'60px'} />
                  </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Transportations services for customer cargos

</p>
</div>
      </Col>

      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#D3D327',padding:'30px',borderRadius:'50%'}}>
                  <img src='/storage_1261055.png' width={'60px'} />                     </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Warehousing facility inside JAFZA & Dubai Industrial City

</p>
</div>
      </Col>

      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#D68CFF',padding:'30px',borderRadius:'50%'}}> 
                  <img src='\file-extensions_8761827.png'  width={'60px'}/>
                  </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>RTA documentation

</p>
</div>
      </Col>
      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#E5C252',padding:'30px',borderRadius:'50%'}}>
                  <img src='/container_6491388.png' width={'60px'} />
                     </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Container Stuffing

</p>
</div>
      </Col>
      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#D3D3D3',padding:'30px',borderRadius:'50%'}}> 
                  <img src='/transportation_995334.png' width={'60px'} />
                  </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Sea / Air / Land Ffreight

</p>
</div>
      </Col>


      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#87CEFA',padding:'30px',borderRadius:'50%'}}> 
                  <img src='/maintenance_2034010.png' width={'60px'} />
                  </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Vehicle Up Keep Activities

</p>
</div>
      </Col>
      <Col lg={3}>
      <div className='text-center mb-3' style={{display:'flex',justifyContent:'center'}}>
                    {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{width:'fitcontent',background:'#51CEA1',padding:'15px',borderRadius:'50%'}}> 
                  <img src='/Asest/car-checking-icon-logo-illustration-design-template-vector-removebg-preview.png' width={'90px'} />
                  </div> 
                  </div>
                  <div style={{padding:'10px'}}>
                  <p className='text-center'>Pre Delivery Inspection

</p>
</div>
      </Col>
    </Row>
  </Col>
        </Row>

     

       
      </Container>
      <Contactbanner />

      <Footer />

    </>
  )
}

export default About
