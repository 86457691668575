import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Contactbanner from './Contactbanner';
import { useEffect, useState } from 'react';
import axios from 'axios';
import 'animate.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { MdImportExport } from "react-icons/md";
import { LiaWarehouseSolid } from "react-icons/lia";
import { MdEmojiTransportation } from "react-icons/md";
import { FaWarehouse } from "react-icons/fa";
import { IoDocumentTextOutline } from "react-icons/io5";
import { GoContainer } from "react-icons/go";
import { GiCargoShip } from "react-icons/gi";
import { FaCarOn } from "react-icons/fa6";
import { GrHomeRounded } from "react-icons/gr";
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { GiMechanicGarage } from "react-icons/gi";



function UncontrolledExample() {
  const [images, setImages] = useState()
  const [about, setAbout] = useState()
  useEffect(() => {
    axios.get('https://sub8.sbagh.com/api/getBanners')
      .then(response => { setImages(response.data.data) })

    axios.get('https://sub8.sbagh.com/api/getDataByMenu/about-us')
      .then(response => {
        setAbout(response.data.data.content
        )
      })

  }, [])

  console.log(about)


  return (
    <>
    <Helmet>
    <title>Bahwan Warehousing - Home</title>
        <meta name="description" content="Home-Page" />
        <meta name="keywords" content="Homepage"></meta>
    </Helmet>
      <Container fluid  >
        <Row>
          <Carousel style={{ padding: '0px' }} indicators={false}>


            {images && images.map((item) => {
              return (
                <Carousel.Item>

                  <Carousel.Caption className='text-start caption-skew  animate__backInLeft ' style={{ left: '0%' }}>
                    <div>
                      <div >
                        <h4 >{item.first_title}</h4>
                      </div>
                      <div >
                        <h6>{item.second_title}</h6>
                      </div>
                    </div>
                  </Carousel.Caption>
                  <img
                    className="d-block w-100"
                    src={item.large_image_path + item.slider_image}
                    alt="First slide"
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
        </Row>


        <Row className='home-about-row mb-5 mt-5'>
          <Col lg={11} className='home-about'>
            <Row style={{ padding: '30px' }}>

              <Col lg={6} className='col-about-content'>
                <div>
                  <h3>{about && about.first_title} .</h3>

                <p>Bahwan Auto Warehousing (BAW) is a logistics service provider operating from JAFZA since 2009.</p>
                <p>BAWH have well-built open yards and covered warehouse inside JAFZA and Dubai Industrial City. The open yard in JAFZA measures 120000 Sq Mtr and Dubai Industrial City with yard size of 100,000 Sq Mtr. We operate covered warehouse inside JAFZA and Dubai Industrial City.</p>
                  <button className="rounded-pill">
                    <Link to="/about-us">Read More..</Link>
                  </button>
                </div>
              </Col>

              <Col lg={6} style={{ paddingLeft: '20px', paddingRight: '0px' }}>
                {/* <Cheaking/> */}
                {about &&
                  <OwlCarousel items={1}
                    className="owl-theme"
                    loop
                    margin={8} >
                    <div className='text-center' style={{ display: 'block', alignItems: 'center', alignContent: 'center' }}   >
                      <div style={{ display: 'flex', justifyContent: 'center', height: '280px' }}>
                        <img src={about.large_image_path + about.image1} width={'100%'} style={{ borderRadius: '10px' }} />
                      </div>
                      <div>
                        <h5 className='mt-4'>Storage</h5>
                        <p className='mt-2'> Bahwan Auto Warehousing (BAW) is a logistics service provider operating from JAFZA since 2009..</p>
                      </div>
                    </div>

                    <div className='text-center' style={{ display: 'block', alignItems: 'center', alignContent: 'center' }}   >
                      <div style={{ display: 'flex', justifyContent: 'center', height: '280px' }}>
                        <img src={about.large_image_path + about.image} width={'100%'} style={{ borderRadius: '10px' }} />
                      </div>
                      <div>
                        <h5 className='mt-4'>Information</h5>
                        <p className='mt-2'>Bahwan Auto Warehousing (BAW) is a logistics service provider operating from JAFZA since 2009..</p>
                      </div>
                    </div>


                  </OwlCarousel>
                }
              </Col>
            </Row>

          </Col>
        </Row>

        <Row style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#f6f6fe' }}>
          <Col lg={10}>
            <h3 className='text-center mb-5 mt-5'>OUR SERVICES</h3>

            <Row className='mt-5' style={{ display: 'flex', justifyContent: 'center' }}>
              <Col lg={3} >
                <div className='text-center mb-3   ' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#90EE90', padding: '30px', borderRadius: '50%' }} className='  animate__backInLeft'> <img src='/communications_8948655.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Import / Export documentation and clearance of shipments

                  </p>
                </div>
              </Col>



              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#ADD8E6', padding: '30px', borderRadius: '50%' }}>
                    <img src='\warehouse_2897683.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Open storage facility inside JAFZA & Dubai Industrial City</p>
                </div>
              </Col>

              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#FFB6C1', padding: '30px', borderRadius: '50%' }}>
                    <img src='/MicrosoftTeams-image (90).png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Transportations services for customer cargos

                  </p>
                </div>
              </Col>

              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#D3D327', padding: '30px', borderRadius: '50%' }}>
                    <img src='/storage_1261055.png' width={'60px'} />                     </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Warehousing facility inside JAFZA & Dubai Industrial City</p>
                </div>
              </Col>

              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#D68CFF', padding: '30px', borderRadius: '50%' }}>
                    <img src='\file-extensions_8761827.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'> RTA documentation </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#E5C252', padding: '30px', borderRadius: '50%' }}>
                    <img src='/container_6491388.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Container Stuffing </p>
                </div>
              </Col>
              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#D3D3D3', padding: '30px', borderRadius: '50%' }}>
                    <img src='/transportation_995334.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Sea / Air / Land Ffreight </p></div>
              </Col>


              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#87CEFA', padding: '30px', borderRadius: '50%' }}>
                    <img src='/maintenance_2034010.png' width={'60px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Vehicle Up Keep Activities</p></div>
              </Col>
              <Col lg={3}>
                <div className='text-center mb-3' style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <BsArrowLeftRight style={{ fontSize: '50px' }} /> */}
                  <div style={{ width: 'fitcontent', background: '#51CEA1', padding: '16px', borderRadius: '50%' }}>
                    <img src='/Asest/car-checking-icon-logo-illustration-design-template-vector-removebg-preview.png' width={'90px'} />
                  </div>
                </div>
                <div style={{ padding: '10px' }}>
                  <p className='text-center'>Pre Delivery Inspection</p></div>
              </Col>
            </Row>
          </Col>
        </Row>

{/* 
        <Row  style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={10}>
            <h3 className='text-center  mt-4 mb-4' style={{ color: 'black', marginBottom: '0' }}>OUR SERVICES</h3>

            <Row className=' mb-4' style={{ display: 'flex', justifyContent: 'center' }}>
              <Col lg={3} >
                
                <Card className='new-card-service' >    
                     <div className='content' >
                      <div className='div-1'>
                      <MdImportExport  fontSize={'60px'}/>
                      </div>
                      <div className='content-div' > 
                    <p className='text-center '>Import / Export documentation and clearance of shipments
                    </p>
                    </div>
                  </div>
                </Card>
              </Col>


              <Col lg={3} >
                
                <Card className='new-card-service' >    
                     <div className='content' >
                      <div className='div-1' style={{position:'relative',right:'0px'}}>
                      <LiaWarehouseSolid    fontSize={'60px'}/>
                      </div>
                      <div className='content-div' > 
                    <p className='text-center '>Open storage facility inside JAFZA & Dubai Industrial City


                    </p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col lg={3} >
                
                <Card className='new-card-service' >    
                     <div className='content' >
                      <div className='div-1'>
                      <MdEmojiTransportation  fontSize={'60px'}/>
                      </div>
                      <div className='content-div' > 
                    <p className='text-center '>Transportations services for customer cargos


                    </p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col lg={3} >
                
                <Card className='new-card-service' >    
                     <div className='content' >
                      <div className='div-1'>
                      < FaWarehouse  fontSize={'60px'}/>
                      </div>
                      <div className='content-div' > 
                    <p className='text-center '>Warehousing facility inside JAFZA & Dubai Industrial City
                    </p>
                    </div>
                  </div>
                </Card>
              </Col>

             

             
            </Row>
          </Col>
        </Row> */}







      </Container>







      <Contactbanner />
      <Footer />

    </>
  );
}

export default UncontrolledExample;