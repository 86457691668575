import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from 'react-icons/fa'
import { useEffect, useState } from 'react';
import { Button, Modal, NavDropdown } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import $ from 'jquery'
import Eform from './Eform';
import axios from 'axios';
import { Col, Row } from "react-bootstrap";

// import NavDropdown from 'react-bootstrap/NavDropdown';
function BasicExample() {
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false)
  const [opend, setOpend] = useState(false)
  const [menus, setMenus] = useState()
  const handleModal = () => {
    setShowModal(!showModal);
  };



  useEffect(() => {
    axios.get('https://sub8.sbagh.com/api/getMenus')
      .then(response => setMenus(response.data.data))

  }, [])


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the form data (e.g., submit to a server)
  };

  return (
    <>
      <Navbar expand="lg" sticky='top'>
        <Container >
          <Row style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>

            <Col lg={2} className='text-start' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Link to={'/'}>  <img src='/Asest/logo.png' width={'140px'} /></Link>  
            </Col>
            <Col lg={8}>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav" className='hides'>
                <Nav className="me-auto">
                  {menus && menus.map((item) => {
                    return (
                      item.menu_name === 'Media' ?

                        <NavDropdown title="Media" id="basic-nav-dropdown"
                          show={open}
                          onMouseEnter={() => setOpen(true)}
                          onMouseLeave={() => setOpen(false)}>
                          {item.children.map((item) => {
                            return (
                              <NavDropdown.Item
                              ><NavLink to={item.slug} style={{ textDecoration: 'none', color: 'black' }}> {item.menu_name}</NavLink></NavDropdown.Item>
                            )
                          })}
                        </NavDropdown> : <Nav.Item><NavLink to={item.slug} className="nav-link"  >{item.menu_name
                        }</NavLink></Nav.Item>
                    )
                  })}

                  {/* <Nav.Link   href="#home"><Link to={'/about'}>About Us</Link></Nav.Link>
              <Nav.Link href="#home"><Link to={'/services'}>Services</Link></Nav.Link>
              
              

              <Nav.Link href="#home"><Link to={'/contact'}>Contact Us</Link></Nav.Link> */}


                </Nav>


              </Navbar.Collapse>

              {/* <Nav className='icons-nav'>
          
        <Nav.Link href="#home"><Link to={'/'}><FaFacebookSquare/></Link></Nav.Link>
            <Nav.Link href="#home"><Link to={'/'}><FaLinkedin/></Link></Nav.Link>
            <Nav.Link><FaTwitterSquare/></Nav.Link>

        </Nav> */}

            </Col>
            <Col lg={2} className='text-end' style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
              <Nav.Link onClick={handleModal} style={{ padding: '0px' }} ><Button className='enquiry'>Enquiry</Button> </Nav.Link>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Modal show={showModal} onHide={handleModal}>



        <div className="container ">
          <Row style={{ background: '#0D6EFD' }}>
            <Col>
              <h6 style={{ padding: '15px 10px 10px 20px', color: 'white' }}>Enquiry Form</h6>
            </Col>
            <Col className="text-end">
              <h6 onClick={handleModal} style={{ padding: '15px 24px 10px 10px', color: 'white' }}>x</h6>
            </Col>
          </Row>
          <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
            <div className="mb-3">

              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">

              <input
                type="email"
                className="form-control"
                placeholder="Email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">

              <input
                type="tel"
                className="form-control"
                placeholder="Mobile"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">

              <textarea
                className="form-control"
                placeholder="Message"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary mb-2 mt-2 text-center">
                Submit
              </button>
            </div>
          </form>
        </div>
        {/* <Eform/> */}
      </Modal>


    </>
  );
}

export default BasicExample;























