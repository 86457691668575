import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { MdEmail, MdPhone } from 'react-icons/md';
import {FiMapPin} from 'react-icons/fi'
import { Helmet } from 'react-helmet';
function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // e.g., send the form data to the server
  
    setEmail('')
    setMessage('')
    setName('')
  };
  return (
    <>
    
    <Helmet>
        <title>Bahwan Warehousing - Contact-Us</title>
        <meta name="description" content="Contactpage" />
        <meta name="keywords" content="Contactpage"></meta>
    </Helmet>

      <Container className='Contact'>
      <h1 className='mt-4 mb-4 text-center'>Contact Us</h1>
      <Row className='mb-4'>
        <Col lg={10}>
          <Row>
            
            <Col lg={4} className='icon-col text-center' >

              <Card>
              <div className='icon-circle-div'>
              <div className="icon-circle ">
                <MdEmail className='icon-color mt-5 mb-1'/>
              </div>
              </div>
              <div className='divs-p'>
              <p>
sales@bahwanautowarehousing.ae
binu@dtglobal.com</p>
</div>

</Card>
            </Col>

            <Col lg={4} className='icon-col text-center' >
              <Card>
              <div className='icon-circle-div'>
              <div className="icon-circle ">
                <MdPhone className='icon-color mt-5 mb-1'/>
              </div>
              </div>
              <div className='divs-p'>
              <p>+971 50 462 2615</p>
              </div>
              </Card>
            </Col>


            <Col lg={4} className='icon-col text-center' >
              <Card>
              <div className='icon-circle-div'>
              <div className="icon-circle ">
                <FiMapPin className='icon-color mt-5 mb-1'/>
              </div>
              </div>
              <div className='divs-p'>
              <p>Jebel Ali, Dubai</p>
              </div>
              </Card>
            </Col>
          </Row>



        </Col>



      </Row>



      

        <Row className='mb-5'>
          <Col lg={10}>
            <Row>
              <Col lg={6} className='form-col'>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className='mb-1'
                    />
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className='mb-1'
                    />
                  </Form.Group>

                  <Form.Group controlId="formMessage">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)} 
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className='mt-4 contact-form-btn'>
                    Submit
                  </Button>
                </Form>
              </Col>

              <Col lg={6} className='contact-design' style={{padding:'0px'}}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30671204.388655715!2d48.45013005931628!3d20.215513162170282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f0d6b14302ad3%3A0x7c1ab1a59ffc6dca!2sBahwan%20Auto%20Warehous%20Jebel%20Ali!5e0!3m2!1sen!2sin!4v1688553075316!5m2!1sen!2sin" width="100%" height="100%"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
               
              </Col>
            </Row>

          </Col>
        </Row>



        

      </Container>

    </>
  )
}

export default Contact
