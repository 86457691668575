import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageGallery = () => {
  // Sample images (replace these with your own)
  const images = [
    '/Asest/about-2.jpg',
    '/Asest/about-1.jpg',
    '/Asest/about-2.jpg', 
    '/Asest/about-1.jpg',
    // Add more images as needed
  ];

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = (index) => {
    setLightboxOpen(true);
    setLightboxIndex(index);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
    <Helmet>
    <title>Bahwan Warehousing - Gallery</title>
        <meta name="description" content="Gallery" />
        <meta name="keywords" content="Gallery"></meta>
    </Helmet>
    <Container>
      <Row className='mt-5 mb-5'>
        <h3 className='text-center mb-5'>Gallery</h3>
        {images.map((image, index) => (
          <Col key={index} xs={6} md={4} lg={3} onClick={() => openLightbox(index)}>
            <img src={image} alt={`Image ${index + 1}`} className="img-fluid" />
          </Col>
        ))}
      </Row>
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[lightboxIndex]}
          nextSrc={images[(lightboxIndex + 1) % images.length]}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setLightboxIndex((lightboxIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      )}
    </Container>
    </>
  );
};

export default ImageGallery;
