import React from 'react'

function Media2() {
  return (
    <div>
      <h1>Media</h1>    </div>
  )
}

export default Media2
