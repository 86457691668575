import React, { createContext, useEffect, useState} from 'react';
import Navber from './Components/Navber';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Components/Home';
import Media from './Components/Media';
import Services from './Components/Services';
import Contact from './Components/Contact';
import About from './Components/About';
import Scrolltop from './Components/Scrolltop';
import News from './Components/News';
import Media2 from './Components/Media2';
import axios from 'axios';

export const Store = createContext()

function App() {

  const[data,setData]=useState([
  {imgage:'/communications_8948655.png',name:"Import / Export documentation and clearance of shipments",color:'red'},
  {imgage:'\warehouse_2897683.png',name:"Open storage facility inside JAFZA & Dubai Industrial City",color:'blue'},
  {imgage:'/MicrosoftTeams-image (90).png',name:"Transportations services for customer cargos",color:'green'},
  {imgage:'/storage_1261055.png',name:"Warehousing facility inside JAFZA & Dubai Industrial City",color:'skyblue'},
  {imgage:'file-extensions_8761827.png',name:"RTA documentation",color:'black'},
  {imgage:'/container_6491388.png',name:"Container Stuffing",color:'green'},
  {imgage:'transportation_995334.png',name:'Sea / Air / Land Ffreight',color:'green'},
  {imgage:'/maintenance_2034010.png',name:'Vehicle Up Keep Activities',color:'green'},
  {imgage:'cargo-ship_10123904.png',name:'PDI',color:'green'},
  {imgage:'',name:''},

  ])


  const[about,setAbout]=useState()

  useEffect(()=>{
    axios.get('https://sub8.sbagh.com/api/getDataByMenu/about-us')
    .then(response => {setAbout(response.data.data.content)})


  },[])

  return (
    <Store.Provider value={[about,setAbout]}> 


    <Scrolltop/>
    <Navber/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/contact-us" element={<Contact/>} />
        <Route path="/gallery" element={<Media/>} />
        <Route path="/services" element={<Services/>} />
        <Route path='/news'     element={<News/>}/>
      </Routes>
    
     
    </Store.Provider>
     
      
    
  )
}

export default App
