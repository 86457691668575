// src/NewsBlocks.js
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { BiSolidLeftArrow } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';

const News = () => {
  // Replace this data with your actual news data
  const newsData = [
    {
      title: 'News Article 1',
      description: 'This is the first news article.',
      image: '/Asest/new-img7.png',
    },
    {
      title: 'News Article 2',
      description: 'This is the second news article.',
      image: '/Asest/new-img9.png',
    },
    {
        title: 'News Article 3',
        description: 'This is the third news article.',
        image: '/Asest/new-img8.png',
      },
    // Add more news items as needed
  ];

  return (
    <>
    <Helmet>
    <title>Bahwan Warehousing - News</title>
        <meta name="description" content="Newspage" />
        <meta name="keywords" content="Newspage"></meta>
    </Helmet>
    <Container className='mt-5'>
     
      <Row>
      {newsData.map((item)=>{
        return(
        <Col lg={4}>
          <Card>
            <Card.Header style={{padding:'0px'}} className='image-container'>
              
                <img src={item.image} width={'100%'} />
             
            </Card.Header>
            <Card.Body>
              <h5>{item.title}</h5>
              <p>{item.description}</p>

              <p>Read More <BsArrowRight/></p>
            </Card.Body>
          </Card>
        </Col>
          )
        })}
      </Row>
    
    </Container>
    </>
  );
};

export default News;




