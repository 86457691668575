import React from 'react'
import { Container,Row,Col,Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Contactbanner() {
  return (
    <>

    <Container fluid >
       <Row className='contact-us'>
          <Col lg={10}  style={{ display: 'flex', justifyContent: 'center' }}>
            <p className='cont-text'>Find out how we can help</p>
          </Col>

<Col lg={10} style={{ display: 'flex', justifyContent: 'center' }} >
<Row style={{width:'100%'}}>
  <Col lg={6} style={{display:'flex',justifyContent:'end'}}>
  <Button className='contact-us-btn'><Link to={'/contact'}>Contact us </Link></Button>
  </Col>
  <Col lg={6}>
  <Button className='contact-us-btn-1'> <Link to={'/services'}>What we do</Link></Button>
  </Col>
  </Row>


</Col>

</Row>
    </Container>
      
    </>
  )
}

export default Contactbanner
