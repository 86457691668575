import React, { useContext, useEffect, useState } from 'react'
import { Container ,Row,Col, Carousel} from 'react-bootstrap'
import Footer from './Footer'
import Contactbanner from './Contactbanner'
import axios from 'axios'
import { Store } from '../App'
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet'

function About() {

const[about,setAbout]= useContext(Store)
  
  

console.log(about)
  return (
    <>
<Helmet>
<title>Bahwan Warehousing - About-Us</title>
        <meta name="description" content="About-Us" />
        <meta name="keywords" content="About-Us"></meta>
</Helmet>
    <Container  fluid>

      <Row>
      <Carousel style={{padding:'0px'}}>
        <Carousel.Item>
          <img  src='/Asest/about-img.png' />
        </Carousel.Item>
      </Carousel>

      </Row> 
    <Row style={{display:'flex',justifyContent:'center'}}>
      <Col lg={10}>
        <Row>
          <Col lg={6} className='mb-5 mt-5'>
            <div>
              <img src='/Asest/5a71e5db75375500014c9af5_Nuneaton Storage.jpg' width={'100%'} />
            </div>
          </Col>
          <Col lg={6} className='mt-5 mb-5'>
{/* <p>Bahwan Auto Warehousing (BAW) is alogistics company operating from JAFZA since 2009.</p>
<p>BAW have well built open yards and covered warehouse inside JAFZA and Dubai Industrial City. The open yard in
JAFZA measures 65000 Sq Mtr and Dubai Industrial City with size of 100,000 Sq Mtr.
We operates covered warehouse of 3500 Sq Mtr inside JAFZA with 2500 Sq Mtr pallet racks and 1000 Sq Mtr floor area.</p>         
<h6 >Our Service Includes</h6>
<ul>
<li> Import / Export documentation and clearance of shipments</li>
<li>Open storage facility inside JAFZA & Dubai Industrial City</li>
<li>Warehousing facility inside JAFZA & Dubai Industrial City</li>
<li>Transportations services for customer cargos</li>
<li>RTA documentation</li>
<li>Container Stuffing</li>
<li>Sea freight</li> */}

{about && 
<p>{parse(about.first_description
)}</p>

}
         
          </Col>
        </Row>
      </Col>
    </Row>
    </Container>
    <Contactbanner/>

<Footer/>
  
    </>
  )
}

export default About
